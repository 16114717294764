/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { stripSpaces } from '../../utils/helpers/helpers';

@Pipe({
	name: 'numberOfCharactersPipe',
})
export class NumberOfCharactersPipe implements PipeTransform {
	public transform(value: string | null): number {
		return value ? stripSpaces(value).length : 0;
	}
}
