/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import {
	Component,
	ContentChild,
	EventEmitter,
	forwardRef,
	Injector,
	Input,
	OnInit,
	Output,
	TemplateRef,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControlDirective,
	FormControlName,
	FormGroupDirective,
	NG_VALUE_ACCESSOR,
	NgControl,
	UntypedFormGroup,
} from '@angular/forms';
import { stripSpaces, unescapeHtml } from '../../../utils/helpers/helpers';

@Component({
	selector: 'input-field',
	templateUrl: 'input-field.component.html',
	styleUrls: ['input-field.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputFieldComponent),
			multi: true,
		},
	],
	standalone: false,
})
export class InputFieldComponent implements ControlValueAccessor, OnInit {
	@Input() public type = 'text';
	@Input() public placeholder: string;
	@Input() public readonly: boolean;
	@Input() public form: UntypedFormGroup;
	@Input() public errorMessageHidden: boolean;
	@Input() public errorMessage: string;
	@Input() public className: string;
	@Input() public stripSpaces = true;
	@Input() public maxLength: number;
	@Input() public step: number;
	@Input() public min: number;
	@Input() public max: number;
	@Input() public unescapeHtml: boolean;
	@Input() public set disableControl(condition: boolean) {
		this.disabled = condition;
	}
	@Input() public transformToDisplayValue: (originalValue: any) => string = (originalValue: any) =>
		originalValue;
	@Input() public transformToOriginalValue: (displayValue: string) => any = (
		displayValue: string,
	) => displayValue;
	@Output() public readonly blur: EventEmitter<Event> = new EventEmitter();

	@ContentChild('errorsTemplate') public errorsTemplate: TemplateRef<any>;

	public value: string;
	public currentCharactersCount: number;
	public control: AbstractControl;
	protected disabled: boolean;

	constructor(private readonly injector: Injector) {}

	public ngOnInit(): void {
		// To avoid circular dependency caused by CVA provider registration
		const ngControl: unknown = this.injector.get(NgControl);

		if (ngControl instanceof FormControlName) {
			this.control = this.injector.get(FormGroupDirective).getControl(ngControl);
		} else {
			this.control = (ngControl as FormControlDirective).form;
		}
	}

	private setDisplayValue(newValue: string) {
		this.value = newValue;
	}

	private setOriginalValue(newValue: any) {
		this.value = this.transformToDisplayValue(newValue);
	}

	public onChange(event): void {
		this.changeInputValue(event.target.value);
	}

	public onBlur(event): void {
		const inputValue: string = this.optimizeValue(event.target.value);

		if (inputValue !== this.value) {
			this.changeInputValue(inputValue);

			// HACK: the value wasn't changed in the UI without double change
			// this.value = strippedValue + ' '; // seems this wasn't needed
			setTimeout(() => {
				this.setDisplayValue(this.getCleanValue(inputValue));
			});
		}

		this.blur.emit(event);
	}

	public writeValue(value: string): void {
		this.setOriginalValue(this.getCleanValue(value));
	}

	public registerOnChange(fn: any): void {
		this.propagateChange = fn;
	}

	public registerOnTouched(): void {}

	public propagateChange(_: any) {}

	// public setDisabledState(disabled: boolean): void {
	// 	this.disabled = disabled;
	// 	this.changeDetectionRef.detectChanges();
	// }

	private optimizeValue(value: string): string {
		return value && this.stripSpaces ? stripSpaces(value) : value;
	}

	private changeInputValue(newInputValue: string): void {
		this.setDisplayValue(newInputValue);
		this.propagateChange(this.transformToOriginalValue(this.value));
	}

	private getCleanValue(newValue: string): string {
		return this.unescapeHtml ? unescapeHtml(newValue) : newValue;
	}
}
