<input
	[attr.maxlength]="maxLength ? maxLength : null"
	class="input {{ className }}"
	[disabled]="disabled"
	max="{{ max ? max : undefined }}"
	min="{{ min ? min : undefined }}"
	[placeholder]="placeholder"
	[readonly]="readonly"
	step="{{ step ? step : undefined }}"
	[type]="type"
	[value]="value"
	(blur)="onBlur($event)"
	(input)="onChange($event)"
/>

<character-limit *ngIf="maxLength" [current]="value | numberOfCharactersPipe" [max]="maxLength" />

<div *ngIf="!errorMessageHidden && control?.invalid" class="error-container">
	<div class="error">
		<mat-icon *ngIf="errorMessage || errorsTemplate" class="error__icon" svgIcon="ic-close-small" />

		<span *ngIf="!errorsTemplate && errorMessage" class="error__text">{{ errorMessage }}</span>
		<ng-template
			[ngTemplateOutlet]="errorsTemplate"
			[ngTemplateOutletContext]="{ errors: control?.errors }"
		/>
	</div>
</div>
